import React from 'react';
import logo from './assets/logo (3).png'

function Service() {
  return (
    <div>
     <section className="service_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>Our Services</h2>
            <img src="images/plug.png" alt="" />
          </div>

          <div className="service_container">
            <div className="box">
              <div className="img-box">
                <img src="images/s1.png" className="img1" alt="Installation" />
              </div>
              <div className="detail-box">
                <h5>Pipe Installation</h5> <p>We offer expert installation services for all types of agricultural pipes, ensuring efficient water distribution for your crops.</p> </div> </div> <div className="box active"> <div className="img-box"> <img src="images/s2.png" className="img1" alt="Irrigation Systems" /> </div> <div className="detail-box"> <h5>Irrigation Systems</h5> <p>Our advanced irrigation systems are designed to maximize water use efficiency, helping you maintain healthy crops.</p> </div> </div> <div className="box"> <div className="img-box"> <img src="images/s3.png" className="img1" alt="Maintenance" /> </div> <div className="detail-box"> <h5>Pipe Maintenance</h5> <p>We provide maintenance services to ensure the longevity and optimal performance of your agricultural pipes.</p> </div> </div> <div className="box"> <div className="img-box"> <img src="images/s4.png" className="img1" alt="Custom Solutions" /> </div> <div className="detail-box"> <h5>Custom Solutions</h5> <p>Tailored pipe solutions designed to meet the unique needs of your agricultural setup.</p> </div> </div> <div className="box"> <div className="img-box"> <img src="images/s5.png" className="img1" alt="Consultation" /> </div> <div className="detail-box"> <h5>Expert Consultation</h5> <p>Our experts are available for consultations to help you choose the best piping solutions for your farm.</p> </div> </div> </div> <div className="btn-box"> <a href="">Read More</a> </div> </div> </section>



               

    </div>
  );
}

export default Service;
