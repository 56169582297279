import React from 'react'
import logo from './assets/logo (3).png'
import './Footer.css'
function Footer() {
  return (
    <div>

<section className="info_section layout_padding">
        <div className="container" style={{ height: '-20vh' }}>
          <div className="info_contact">
            <div className="row" style={{ marginTop: '-10vh' }}>
              <div className="col-md-4">
                <a href="">
                  <img src="images/location-white.png" alt="Location" />
                  <span>GSTIN: 27AAMPC0305Q2ZN Westend Industries J-36 MIDC AREA JALGAON</span>
                </a>
              </div>
              <div className="col-md-4">
                <a href="">
                  <img src="images/telephone-white.png" alt="Call Us" />
                  <span>Call: +91 91588 80011</span>
                </a>
                
              </div>
              <div>
              <div className="col-md-12">
                <img src={logo} alt="Company Logo" style={{ width: '250px', marginBottom: '20px', marginTop:'-30px' }} className='log'/>
              </div>

              </div>
            </div>
          </div>
        </div>
      </section>


       <footer className="container-fluid footer_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-9 mx-auto">
              <p>
                &copy; 2024 All Rights Reserved By
                <a href="https://www.shreedigimarketing.in/"> shree digital marketing agency </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer