import React, { useState } from 'react';
import './Pvc.css'; // If you want to style this page
import pvcImage from './assets/u26.jpg'; // Adjust the path as necessary
import newImage from './assets/u18.jpg';
import newwImage from './assets/u13.jpg'; // Import your new image here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function PVCpipes() {
  const [isInstallationOpen, setIsInstallationOpen] = useState(false);
  const [isAdvantagesOpen, setIsAdvantagesOpen] = useState(false);
  const [isPrecautionsOpen, setIsPrecautionsOpen] = useState(false);

  const toggleAccordion = (setAccordion) => {
    setAccordion((prevState) => !prevState);
  };

  return (
    <div className="pvc-pipes-page">
      <h1>PVC Pipes & Fittings</h1>
      <div className="content-container">
        <div className="text-content">
          <p>We place great emphasis on build quality and craftsmanship.</p>

          <h2>About PVC Pipes & Fittings</h2>
          <p>
            Urja PVC pipes are the advanced substitute to the conventional water system. They have many advantages over cast iron, asbestos, and cement pipes. Urja PVC Pipes & Fittings can be used independently for agriculture, irrigation, and the transportation of water. Urja PVC Selfit (Solvent Cement Joint) Pipes have one end self-socketed and the other end plain, which fits snugly without the use of couplers. The strong solvent cement joint is permanent and trouble-free.
          </p>

          <h2>SUPREME GOLD Quick Fix Socket</h2>
          <p>SUPREME GOLD Quick Fix Socket is specially designed for repairing PVC pipelines.</p>

          <h3>Standards & Specifications:</h3>
          <ul>
            <li>IS 4985 : 2000 Specification for PVC Pipe</li>
            <li>IS 7834 (Pt – 3 & Pt – 4) Specification for Fittings</li>
          </ul>

          <h3>Material:</h3>
          <p>PVC Compound Grade Equivalent to PVC 1120 / 2120</p>

          <h3>Sizes:</h3>
          <p>Pipes: 20 mm to 200 mm | Fittings: 20 mm to 200 mm</p>

          <h3>Length:</h3>
          <p>6 Mtrs.</p>

          <h3>Colour:</h3>
          <p>Gray</p>

          <h2>Features and Properties</h2>
          <ul>
            <li>No Corrosion</li>
            <li>High Flow Rates – No Choking</li>
            <li>UV Stabilized</li>
            <li>Long Life</li>
            <li>Light Weight</li>
            <li>Cost Effective</li>
          </ul>

          <h2>Areas of Application</h2>
          <ul>
            <li>Agricultural pipelines for potable water supply.</li>
            <li>Main & submain line for drip irrigation systems.</li>
            <li>Drain & sewer systems in residences, commercial complexes, resorts, hospitals, academic institutes, and industries.</li>
          </ul>

          {/* Installation Method Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsInstallationOpen)} className="accordion-header">
            Installation Method 
            <FontAwesomeIcon icon={isInstallationOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isInstallationOpen && (
            <div className="accordion-content">
              <p>
               urja PVC Pipes & fittings are very convenient & simple to install. 
               urja PVC Pipes can be pre-fixed & laid to the desired length or can be fixed on the site as per requirement. 
                Fix the joints using urja Solvent cement. 
                Provide air valves at regular intervals of 300 mtrs. on plane areas and at all sharp turns. 
                Use urja fittings of proper size & type wherever necessary.
              </p>
            </div>
          )}

          {/* Installation Guide Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsAdvantagesOpen)} className="accordion-header">
            Installation Guide 
            <FontAwesomeIcon icon={isAdvantagesOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isAdvantagesOpen && (
            <div className="accordion-content">
              <p>
                Selfit sockets are formed with high precision on specially developed sophisticated machines. 
                There is a 50% saving in installation time, as compared with plain ended pipes and loose couplers. 
                The number of joints is reduced by 50%, resulting in substantial savings in labor costs. 
                More economical than conventional plain-ended pipes with loose couplers. 
                Good hydraulic properties due to smooth interior pipe surfaces.
              </p>
            </div>
          )}

          {/* Advantages Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsAdvantagesOpen)} className="accordion-header">
            Advantages 
            <FontAwesomeIcon icon={isAdvantagesOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isAdvantagesOpen && (
            <div className="accordion-content">
              <ul>
                <li>Use non-return valves for the water raising system.</li>
                <li>Use couplers if the length is too long. It also assists with maintenance.</li>
              </ul>
            </div>
          )}

          {/* Precautions Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsPrecautionsOpen)} className="accordion-header">
            Precautions 
            <FontAwesomeIcon icon={isPrecautionsOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isPrecautionsOpen && (
            <div className="accordion-content">
              <ul>
                <li>Use a proper adapter while fixing with the existing GI/CI or AC pipe system.</li>
                <li>Provide support at joints & at regular distances. Avoid inclined bends.</li>
                <li>Prefer underground or ducted systems to increase life.</li>
              </ul>
            </div>
          )}
        </div>

        {/* Images container */}
        <div className="image-container">
          <img src={pvcImage} alt="PVC Pipes" className="pvc-image" />
          <img src={newImage} alt="Additional PVC Pipes" className="pvc-image" />
          <img src={newwImage} alt="Additional PVC Pipes" className="pvc-image" /> {/* This image will be displayed below the first one */}
        </div>
      </div>
    </div>
  );
}

export default PVCpipes;
