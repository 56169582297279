import React from 'react';
import logo from './assets/logo (3).png';
import './Header.css';

function Header() {
  return (
    <div>
      <header className="header_section">
        <div className="container">
          <nav className="navbar navbar-expand-lg custom_nav-container">
            <a className="navbar-brand" href="index.html">
              <img
                src={logo}
                alt=""
                style={{ height: '80px', width: '180px', marginLeft: '-50px' }}
                className='logo'
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="s-1"> </span>
              <span className="s-2"> </span>
              <span className="s-3"> </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="about">About</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="product" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Products
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="product1">PVC Pipes & Fitting</a>
                      <a className="dropdown-item" href="product2">CPVC Pipes & Fitting</a>
                      <a className="dropdown-item" href="product3">UPVC Pipes & Fitting</a>
                      <a className="dropdown-item" href="product4">HDPE Pipes & Fitting</a>
                      <a className="dropdown-item" href="product5">SWR Pipes & Fitting</a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="dealer">Dealer</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="inquiry">Inquiry</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
