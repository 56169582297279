import React, { useState } from 'react';
import p from './assets/upvc.webp';
import p1 from './assets/Hdpe.jpg';
import p2 from './assets/SWR-pipes.jpg';
import p3 from './assets/sanitary-equipment.jpg';
import p4 from './assets/u7.jpg';
import pi5 from './assets/pip.jpg'
import pip1 from './assets/pip1.jpg'
import pip2 from './assets/Hdpe.jpg'
import ipip from './assets/i pip.avif'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import ipip2 from './assets/ipip2.webp'
import pi1 from './assets/valve.webp'
import pi2 from './assets/presure.jpeg'
import pi3 from './assets/hot.jpeg'
import pi4 from './assets/valve2.jpeg'

import pi6 from './assets/p6.webp'
import pi7 from './assets/p7.webp'
import pi8 from './assets/p8.webp'
import pi9 from './assets/p9.webp'
import logo from './assets/logo (3).png'
import u1 from './assets/u1.jpg';
import u2 from './assets/u2.jpg';
import u3 from './assets/u3.jpg';
import u4 from './assets/u4.jpg';
import u5 from './assets/u5.jpg';
import u6 from './assets/u6.jpg';
import u7 from './assets/u7.jpg';
import u8 from './assets/u8.jpg';
import u9 from './assets/u9.jpg';
import u10 from './assets/u10.jpg';
import u11 from './assets/u11.jpg';
import u12 from './assets/u12.jpg';
import u13 from './assets/u13.jpg';
import u14 from './assets/u4.jpg';
import u15 from './assets/u15.jpg';
import u16 from './assets/u16.jpg';
import u17 from './assets/u17.jpg';
import u18 from './assets/u18.jpg';
import u19 from './assets/u19.jpg';
import u20 from './assets/u20.jpg';
import u21 from './assets/u21.jpg';
import u22 from './assets/u22.jpg';
import u23 from './assets/u23.jpg';
import u24 from './assets/u24.jpg';
import u25 from './assets/u25.jpg';
import u26 from './assets/u26.jpg';
import tiger from './assets/tiger-removebg-preview (1).png'
import pd from './assets/p-removebg-preview.png'
import './Home.css'

function Home() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappNumber = '91588 80011'; // WhatsApp number to send the message to

    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/${whatsappNumber.replace('+', '')}?text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.message}`
    )}`;

    // Redirect to WhatsApp with the pre-filled message
    window.open(whatsappURL, '_blank');
  };


  const productData = [
    { image: u1, name: "Product 1" },
    { image: u2, name: "Product 2" },
    { image: u3, name: "Product 3" },
    { image: u4, name: "Product 4" },
    { image: u5, name: "Product 5" },
    { image: u6, name: "Product 6" },
    { image: u7, name: "Product 7" },
    { image: u8, name: "Product 8" },
    { image: u9, name: "Product 9" },
    { image: u10, name: "Product 10" },
    { image: u11, name: "Product 11" },
    { image: u12, name: "Product 12" },
    { image: u13, name: "Product 13" },
    { image: u14, name: "Product 14" },
    { image: u15, name: "Product 15" },
    { image: u16, name: "Product 16" },
    { image: u17, name: "Product 17" },
    { image: u18, name: "Product 18" },
    { image: u19, name: "Product 19" },
    { image: u20, name: "Product 20" },
    { image: u21, name: "Product 21" },
    { image: u22, name: "Product 22" },
    { image: u23, name: "Product 23" },
    { image: u24, name: "Product 24" },
    { image: u25, name: "Product 25" },
    { image: u26, name: "Product 26" },
  ];
  
  // Function to handle WhatsApp redirect
  const handleWhatsAppClick = (productName) => {
    const phoneNumber = "+9191588 80011"; // Replace with your WhatsApp number
    const message = `I'm interested in ${productName}`;
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };
  


  return (
    <div>
    <section className="slider_section">
      
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="detail_box">
        <h4 className='H'>
          High-Quality <br />
          Pipes for Reliable Water Systems
        </h4>
        <p className='p'>
          Our company specializes in manufacturing top-grade pipes designed for efficient and long-lasting water management. Built with precision and tested for durability, our pipes are trusted by industries and professionals across various sectors.
        </p>
        <p className='p'>
           Our commitment to innovation and customer satisfaction has made us a trusted name in the industry.
        </p>
          <a href="contact" className="b">
            Get a Quote
          </a>
        </div>
      </div>
      <div className="col-lg-5 col-md-6 offset-lg-1">
        <div className="img_content">
          <div className="img_container">
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="img-box">
                    <img src={p} alt="Agricultural Pipe 1" className='A' />
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="img-box">
                    <img src={p1} alt="Agricultural Pipe 2" className='A'/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="img-box">
                    <img src={p2} alt="Agricultural Pipe 3" className='A'/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="img-box">
                    <img src={p3} alt="Agricultural Pipe 3" className='A'/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="img-box">
                    <img src={p4} alt="Agricultural Pipe 3" className='A'/>
                  </div>
                </div>
                
              </div>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</section>


<div className="container">
  <div className="image_wrapper">
    <img src={tiger} alt="Tiger" className="animated_tiger" />
    <img src={pd} alt="Lead-Free Pipe" className="animated_pipe" />
    <div className="info_text">
      <h1>High-Quality Lead-Free Pipes</h1>
      <p>
        Made in India, engineered for durability, and built to enhance your water management systems.        Backed by decades of experience, our company is known for its commitment to quality and innovation. As a trusted leader in the industry, we deliver products that you can rely on for consistent performance.
      </p>
      <p>
        Our pipes are lead-free and designed to ensure safe and efficient water flow for various applications.
      </p>
    </div>
  </div>
</div>






     

      <section className="about_section layout_padding">
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <div className="detail_box">
          <div className="heading_container">
            <h2>About Us</h2>
          </div>
          <p>
            We are a leading provider of high-quality agricultural pipes, offering a range of solutions designed to meet the specific needs of farmers. Our products are engineered for durability and efficiency, ensuring that your irrigation systems run smoothly.
          </p>
          <ul className="about_features">
            <li>Complete Product Series</li>
            <li>Convenient Delivery Time</li>
            <li>Cost Performance Advantage</li>
            <li>Close Service</li>
          </ul>
          <a href="about" className="about_link">Know More</a>
        </div>
      </div>
      <div className="col-md-6">
        <div className="img_container">
          <div className="img-box b1">
            <img src={pip1} alt="About Us Image 1" className='Ab' />
          </div>
          <div className="img-box b2">
            <img src={pip2} alt="About Us Image 2" className='Abb' />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  




<section className="contact_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>Contact Us</h2>
            <img src="images/plug.png" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <form onSubmit={handleSubmit}>
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="message"
                    className="message-box"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="d-flex">
                  <button type="submit">SEND</button>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <div className="map_container">
                <div className="map-responsive">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29802.890635028587!2d75.56963836656817!3d20.97814832589251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90ec7a1b5cbfd%3A0x6b62de98742b95eb!2sj%2C%2036%2C%20MIDC%2C%20Jalgaon%2C%20Maharashtra%20425003!5e0!3m2!1sen!2sin!4v1726224594403!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
    </div>
  );
}

export default Home;
