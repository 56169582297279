import React, { useState } from 'react';
import './Inquiry.css'; // Import the updated CSS file

function DealerInquiry() {
  // State to store form inputs
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    businessAddress: '',
    interest: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappNumber = '91588 80011'; // WhatsApp number to send the message to

    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/${whatsappNumber.replace('+', '')}?text=${encodeURIComponent(
      `Dealer Inquiry:\nName: ${formData.name}\nCompany: ${formData.company}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nBusiness Address: ${formData.businessAddress}\nInterest: ${formData.interest}`
    )}`;

    // Redirect to WhatsApp with the pre-filled message
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="dealer-inquiry">
      <section className="form-section">
        <div className="container">
          <div className="heading-container">
            <h2 className="form-heading">Dealer Inquiry</h2>
            <p className="form-subtitle">We would love to hear from you! Fill out the form below to get in touch.</p>
          </div>

          <form onSubmit={handleSubmit} className="inquiry-form">
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Company Name</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Enter your company name"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="businessAddress">Business Address</label>
              <input
                type="text"
                id="businessAddress"
                name="businessAddress"
                placeholder="Enter your business address"
                value={formData.businessAddress}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="interest">Interest in Dealership</label>
              <textarea
                id="interest"
                name="interest"
                placeholder="Describe your interest in the dealership"
                value={formData.interest}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-button">
              <button type="submit" className="submit-button">Send Inquiry</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default DealerInquiry;
