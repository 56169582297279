import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';  
import About from './About';  
import Service from './Service';  
import Contact from './Contact';  
import Header from './Header';
import Footer from './Footer';
import Enquiry from './Enquiry';
import Dealer from './Dealer';
import Product from './Product';
import Pvcpipes from './Pvcpipe';
import Upvc from './Upvc' 
import Cpvc from './Cpvc'
import Hdpe from './Hdpe'
import Swr from './Swr'

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/dealer" element={<Dealer />} />
        <Route path="/inquiry" element={<Enquiry />} />
        <Route path="/product1" element={<Pvcpipes />} /> {/* Change component here */}
        <Route path="/product" element={<Product />} />
        <Route path="/product2" element={<Cpvc />} />
        <Route path="/product3" element={<Upvc />} />
        <Route path="/product4" element={<Hdpe/>} />
        <Route path="/product5" element={<Swr/>} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
