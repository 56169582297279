import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Product.css'; // Importing the CSS file for styles
import u1 from './assets/u1.jpg';
import u2 from './assets/u2.jpg';
import u3 from './assets/u3.jpg';
import u4 from './assets/u4.jpg';
import u5 from './assets/u5.jpg';
import u6 from './assets/u6.jpg';
import u7 from './assets/u7.jpg';
import u8 from './assets/u8.jpg';
import u9 from './assets/u9.jpg';
import u10 from './assets/u10.jpg';
import u11 from './assets/u11.jpg';
import u12 from './assets/u12.jpg';
import u13 from './assets/u13.jpg';
import u14 from './assets/u4.jpg';
import u15 from './assets/u15.jpg';
import u16 from './assets/u16.jpg';
import u17 from './assets/u17.jpg';
import u18 from './assets/u18.jpg';
import u19 from './assets/u19.jpg';
import u20 from './assets/u20.jpg';
import u21 from './assets/u21.jpg';
import u22 from './assets/u22.jpg';
import u23 from './assets/u23.jpg';
import u24 from './assets/u24.jpg';
import u25 from './assets/u25.jpg';
import u26 from './assets/u26.jpg';

// Array of product images and names
const productData = [
  { image: u1, name: "Product 1" },
  { image: u2, name: "Product 2" },
  { image: u3, name: "Product 3" },
  { image: u4, name: "Product 4" },
  { image: u5, name: "Product 5" },
  { image: u6, name: "Product 6" },
  { image: u7, name: "Product 7" },
  { image: u8, name: "Product 8" },
  { image: u9, name: "Product 9" },
  { image: u10, name: "Product 10" },
  { image: u11, name: "Product 11" },
  { image: u12, name: "Product 12" },
  { image: u13, name: "Product 13" },
  { image: u14, name: "Product 14" },
  { image: u15, name: "Product 15" },
  { image: u16, name: "Product 16" },
  { image: u17, name: "Product 17" },
  { image: u18, name: "Product 18" },
  { image: u19, name: "Product 19" },
  { image: u20, name: "Product 20" },
  { image: u21, name: "Product 21" },
  { image: u22, name: "Product 22" },
  { image: u23, name: "Product 23" },
  { image: u24, name: "Product 24" },
  { image: u25, name: "Product 25" },
  { image: u26, name: "Product 26" },
];

// Function to handle WhatsApp redirect
const handleWhatsAppClick = (productName) => {
  const phoneNumber = "+9191588 80011"; // Replace with your WhatsApp number
  const message = `I'm interested in ${productName}`;
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
  window.open(url, '_blank');
};

function Product() {
  return (
    <div className="product-page">
      <h1 className="product-heading">Our Products</h1>
      <div className="product-container">
        {productData.map((product, index) => (
          <div key={index} className="product-card">
            <LazyLoadImage
              src={product.image}
              alt={product.name}
              className="product-image"
              effect="blur" // Add a blur effect while the image is loading
            />
            <h2 className="product-title">{product.name}</h2>
            <button
              className="product-button"
              onClick={() => handleWhatsAppClick(product.name)}
            >
              Buy Now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Product;
