import React, { useState } from 'react';
import './Pvc.css'; // If you want to style this page
import pvcImage from './assets/u5.jpg'; // Adjust the path as necessary
import newImage from './assets/u23.jpg'; // Import your new image here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function CPVCpipes() {
  const [isInstallationOpen, setIsInstallationOpen] = useState(false);
  const [isAdvantagesOpen, setIsAdvantagesOpen] = useState(false);
  const [isPrecautionsOpen, setIsPrecautionsOpen] = useState(false);

  const toggleAccordion = (setAccordion) => {
    setAccordion((prevState) => !prevState);
  };

  return (
    <div className="pvc-pipes-page">
      <h1>CPVC Pipes & Fittings</h1>
      <div className="content-container">
        <div className="text-content">
          <p>We place great emphasis on build quality and craftsmanship.</p>

          <h2>About CPVC Pipes & Fittings</h2>
          <p>
           Urja CPVC (Chlorinated Polyvinyl Chloride) Pipes & Fittings provide the best solution for hot and cold water plumbing systems. They meet all requirements of IS: 15778, ASTM D 2846 & ASTM F 441.Urja CPVC is a more logical choice over copper, GI, or any other plastic alternatives. Produced using the latest state-of-the-art machinery and technology, these pipes ensure the highest international quality standards. The system is certified with ISO 9001: 2008 and consistently aims to achieve the highest levels of customer satisfaction.
          </p>
          <p>
           Urja CPVC pipes are produced in Copper Tube Size (CTS) ranging from 15mm (1/2″) to 100mm (4″).
          </p>

          <h2>Advantages</h2>
          <ul>
            <li>Non-toxic</li>
            <li>Corrosion-resistant</li>
            <li>Lightweight</li>
            <li>Long-lasting</li>
            <li>Easy installation</li>
            <li>Higher well yields</li>
          </ul>

          {/* Installation Method Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsInstallationOpen)} className="accordion-header">
            Installation Method 
            <FontAwesomeIcon icon={isInstallationOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isInstallationOpen && (
            <div className="accordion-content">
              <p>
               Urja CPVC Pipes & fittings are very convenient and simple to install. These pipes can be pre-fixed and laid to the desired length, or they can be fixed on-site as per the requirement. Fix the joints usingUrja Solvent cement. Provide air valves at regular intervals of 300 meters on plain areas and at all sharp turns. UseUrja fittings of proper size and type wherever necessary.
              </p>
            </div>
          )}

          {/* Advantages Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsAdvantagesOpen)} className="accordion-header">
            Additional Advantages 
            <FontAwesomeIcon icon={isAdvantagesOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isAdvantagesOpen && (
            <div className="accordion-content">
              <ul>
                <li>High flow rates with no choking</li>
                <li>UV stabilized for durability</li>
                <li>Cost-effective compared to traditional alternatives</li>
              </ul>
            </div>
          )}

          {/* Precautions Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsPrecautionsOpen)} className="accordion-header">
            Precautions 
            <FontAwesomeIcon icon={isPrecautionsOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isPrecautionsOpen && (
            <div className="accordion-content">
              <ul>
                <li>Use non-return valves for the water-raising system.</li>
                <li>Use couplers if the length is too long. This also assists with maintenance.</li>
                <li>Use proper adapters while fixing with the existing GI/CI or AC pipe system.</li>
                <li>Provide support at joints and at regular intervals. Avoid inclined bends.</li>
                <li>Prefer underground or ducted systems to increase the lifespan of the pipes.</li>
              </ul>
            </div>
          )}
        </div>
       
        {/* Images container */}
        <div className="image-container">
          <img src={pvcImage} alt="CPVC Pipes" className="pvc-image" />
          <img src={newImage} alt="Additional CPVC Pipes" className="pvc-image" />
          {/* This image will be displayed below the first one */}
        </div>
      </div>
    </div>
  );
}

export default CPVCpipes;
