import React, { useState } from 'react';
import './Pvc.css'; // If you want to style this page
import upvcImage1 from './assets/u5.jpg'; // Adjust the path as necessary
import upvcImage2 from './assets/u13.jpg'; // Import your new image here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function UPVCpipes() {
  const [isInstallationOpen, setIsInstallationOpen] = useState(false);
  const [isAdvantagesOpen, setIsAdvantagesOpen] = useState(false);
  const [isPrecautionsOpen, setIsPrecautionsOpen] = useState(false);

  const toggleAccordion = (setAccordion) => {
    setAccordion((prevState) => !prevState);
  };

  return (
    <div className="pvc-pipes-page">
      <h1>UPVC Pipes & Fittings</h1>
      <div className="content-container">
        <div className="text-content">
          <p>We place great emphasis on build quality and craftsmanship.</p>

          <h2>About UPVC Pipes & Fittings</h2>
          <p>
            The Urja protector range of UPVC plain casing and ribbed screen pipes is specially designed to ensure clean, clear water from bore wells, whether shallow or deep. The Protector range is manufactured as per IS 12818 and DIN 4925 standards. Like other pipes, these casing pipes are easy to install. The threaded ends ensure that the pipes can be screwed onto each other, without the use of cement or adhesives.
          </p>

          <h2>Advantages</h2>
          <ul>
            <li>Non-toxic</li>
            <li>Corrosion-resistant</li>
            <li>Lightweight</li>
            <li>Long-lasting</li>
            <li>Easy installation</li>
            <li>Higher well yields</li>
          </ul>

          {/* Installation Guide Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsInstallationOpen)} className="accordion-header">
            Installation Guide 
            <FontAwesomeIcon icon={isInstallationOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isInstallationOpen && (
            <div className="accordion-content">
              <p>
                UPVC casing pipes are simple and quick to install. The threaded ends ensure easy connection by screwing the pipes together, eliminating the need for cement or adhesives. Proper alignment and threading ensure a secure and leak-proof installation.
              </p>
            </div>
          )}

          {/* Advantages Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsAdvantagesOpen)} className="accordion-header">
            Additional Advantages 
            <FontAwesomeIcon icon={isAdvantagesOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isAdvantagesOpen && (
            <div className="accordion-content">
              <ul>
                <li>High flow rates with no choking</li>
                <li>UV stabilized for durability</li>
                <li>Cost-effective compared to steel well casings</li>
              </ul>
            </div>
          )}

          {/* Precautions Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsPrecautionsOpen)} className="accordion-header">
            Precautions 
            <FontAwesomeIcon icon={isPrecautionsOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isPrecautionsOpen && (
            <div className="accordion-content">
              <ul>
                <li>Use non-return valves for water raising systems.</li>
                <li>Use couplers if the length is too long to assist with maintenance.</li>
                <li>Use proper adapters when fixing to the existing GI, CI, or AC pipe system.</li>
                <li>Provide support at joints and at regular distances. Avoid inclined bends.</li>
                <li>Prefer underground or ducted systems to increase the lifespan of the pipes.</li>
              </ul>
            </div>
          )}
        </div>

        {/* Images container */}
        <div className="image-container">
          <img src={upvcImage1} alt="UPVC Pipes 1" className="pvc-image" />
          <img src={upvcImage2} alt="UPVC Pipes 2" className="pvc-image" />
          {/* This image will be displayed below the first one */}
        </div>
      </div>
    </div>
  );
}

export default UPVCpipes;
