// Dealer.js (React component)

import React from 'react';
import './Dealer.css'; // Ensure the CSS file is imported

const Dealer = () => {
  return (
    <div className="finolex-bg">
      <div className="finolex-container">
        <div className="finolex-content">
          <h1 className="finolex-title">Join Our Legacy of Success</h1>
          <p className="finolex-description">
            Become a part of the Urja family and embrace a culture that values growth, teamwork, and groundbreaking ideas.
            Experience the joy of contributing to a trusted brand known for its unwavering commitment to excellence.
          </p>
          <p className="finolex-description">
            As we mark over 4 decades of consistent growth and success, let's embark on a new chapter of innovation and growth together.
          </p>
          <div className="finolex-button-wrapper">
            <a href="inquiry" className="finolex-button">Become a Dealer</a>
            <a href="contact" className="finolex-button">Explore Careers</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dealer;
