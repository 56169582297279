import React, { useState } from 'react';
import './Pvc.css'; // Assuming you're using the same CSS file
import hdpeImage1 from './assets/p3.png'; // Adjust the path to your actual image
import hdpeImage2 from './assets/u23.jpg'; // Adjust the path to your actual image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function HDPEpipes() {
  const [isInstallationOpen, setIsInstallationOpen] = useState(false);
  const [isAdvantagesOpen, setIsAdvantagesOpen] = useState(false);
  const [isPrecautionsOpen, setIsPrecautionsOpen] = useState(false);

  const toggleAccordion = (setAccordion) => {
    setAccordion((prevState) => !prevState);
  };

  return (
    <div className="pvc-pipes-page">
      <h1>HDPE Pipes & Fittings</h1>
      <div className="content-container">
        <div className="text-content">
          <p>We place great emphasis on build quality and craftsmanship.</p>

          <h2>About HDPE Pipes & Fittings</h2>
          <p>
            Urja HDPE (High-Density Polyethylene) Pipes & Fittings are known for their durability, flexibility, and strength. These pipes are widely used in agriculture, irrigation, water supply, gas distribution, and industrial applications. HDPE pipes are resistant to chemicals, corrosion, and abrasion, making them an ideal solution for harsh environments. They comply with IS: 4984 standards and are known for their high performance and long life.
          </p>

          <h2>Advantages</h2>
          <ul>
            <li>Corrosion-resistant</li>
            <li>Flexible and lightweight</li>
            <li>Long-lasting with a lifespan of over 50 years</li>
            <li>Cost-effective for long-term projects</li>
            <li>High impact resistance</li>
          </ul>

          {/* Installation Guide Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsInstallationOpen)} className="accordion-header">
            Installation Guide 
            <FontAwesomeIcon icon={isInstallationOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isInstallationOpen && (
            <div className="accordion-content">
              <p>
                HDPE Pipes are lightweight and easy to handle, making installation faster and more efficient. They are joined using butt fusion or electrofusion methods to create a leak-proof system. These joints provide the same strength as the pipe itself. Ensure proper trenching, bedding, and backfilling during the installation process.
              </p>
            </div>
          )}

          {/* Advantages Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsAdvantagesOpen)} className="accordion-header">
            Additional Advantages 
            <FontAwesomeIcon icon={isAdvantagesOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isAdvantagesOpen && (
            <div className="accordion-content">
              <ul>
                <li>High chemical resistance for industrial applications</li>
                <li>Lower maintenance costs</li>
                <li>Environmentally friendly and recyclable</li>
              </ul>
            </div>
          )}

          {/* Precautions Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsPrecautionsOpen)} className="accordion-header">
            Precautions 
            <FontAwesomeIcon icon={isPrecautionsOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isPrecautionsOpen && (
            <div className="accordion-content">
              <ul>
                <li>Avoid sharp bends during installation to prevent stress points.</li>
                <li>Provide support for above-ground installations.</li>
                <li>Ensure proper jointing techniques to avoid leaks.</li>
                <li>Use appropriate fittings for water, gas, or other applications.</li>
              </ul>
            </div>
          )}
        </div>

        {/* Images container */}
        <div className="image-container">
          <img src={hdpeImage1} alt="HDPE Pipes" className="pvc-image" />
          <img src={hdpeImage2} alt="Additional HDPE Pipes" className="pvc-image" />
        </div>
      </div>
    </div>
  );
}

export default HDPEpipes;
