import React, { useState } from 'react';
import './Pvc.css'; // Assuming you're using the same CSS file
import swrImage1 from './assets/u3.jpg'; // Adjust the path to your actual image
import swrImage2 from './assets/u25.jpg'; // Adjust the path to your actual image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function SWRpipes() {
  const [isInstallationOpen, setIsInstallationOpen] = useState(false);
  const [isAdvantagesOpen, setIsAdvantagesOpen] = useState(false);
  const [isPrecautionsOpen, setIsPrecautionsOpen] = useState(false);

  const toggleAccordion = (setAccordion) => {
    setAccordion((prevState) => !prevState);
  };

  return (
    <div className="pvc-pipes-page">
      <h1>SWR Pipes & Fittings</h1>
      <div className="content-container">
        <div className="text-content">
          <p>We place great emphasis on build quality and craftsmanship.</p>

          <h2>About SWR Pipes & Fittings</h2>
          <p>
            Urja SWR (Soil, Waste, and Rainwater) Pipes & Fittings are designed for the efficient drainage of waste and rainwater in residential, commercial, and industrial buildings. They are made from high-quality UPVC material, providing excellent strength, durability, and leak-proof joints. These pipes comply with IS: 13592 standards and offer a reliable solution for drainage systems.
          </p>

          <h2>Advantages</h2>
          <ul>
            <li>Leak-proof and durable</li>
            <li>UV stabilized for outdoor applications</li>
            <li>Resistant to chemicals and corrosion</li>
            <li>Lightweight and easy to install</li>
            <li>Cost-effective and maintenance-free</li>
          </ul>

          {/* Installation Guide Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsInstallationOpen)} className="accordion-header">
            Installation Guide 
            <FontAwesomeIcon icon={isInstallationOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isInstallationOpen && (
            <div className="accordion-content">
              <p>
                SWR Pipes & Fittings can be easily installed using a rubber ring jointing system, which ensures a leak-proof connection. Proper slope and alignment should be maintained during installation for efficient drainage. For multi-story buildings, provide adequate pipe supports and ventilation.
              </p>
            </div>
          )}

          {/* Advantages Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsAdvantagesOpen)} className="accordion-header">
            Additional Advantages 
            <FontAwesomeIcon icon={isAdvantagesOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isAdvantagesOpen && (
            <div className="accordion-content">
              <ul>
                <li>Non-toxic and safe for drainage systems</li>
                <li>Impact-resistant for long-term use</li>
                <li>Prevents waterlogging and overflow</li>
              </ul>
            </div>
          )}

          {/* Precautions Section with Accordion */}
          <h2 onClick={() => toggleAccordion(setIsPrecautionsOpen)} className="accordion-header">
            Precautions 
            <FontAwesomeIcon icon={isPrecautionsOpen ? faMinus : faPlus} className="accordion-icon" />
          </h2>
          {isPrecautionsOpen && (
            <div className="accordion-content">
              <ul>
                <li>Ensure proper alignment and slope during installation.</li>
                <li>Use appropriate fittings for soil, waste, or rainwater drainage.</li>
                <li>Avoid excessive bending to prevent stress on joints.</li>
                <li>Regularly clean and maintain the system for optimal performance.</li>
              </ul>
            </div>
          )}
        </div>

        {/* Images container */}
        <div className="image-container">
          <img src={swrImage1} alt="SWR Pipes" className="pvc-image" />
          <img src={swrImage2} alt="Additional SWR Pipes" className="pvc-image" />
        </div>
      </div>
    </div>
  );
}

export default SWRpipes;
